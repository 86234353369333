















































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component({
  components: {},
})
export default class SaftExport extends Vue {
  private isLoading = true
  private registerId = null
  private fromTime = ''
  private toTime = ''
  private registers = []
  private mode = 'download'
  private xml = ''

  public created(): void {
    this.isLoading = true
    this.$axios
      .get('/v4/site/payment/cash-registers')
      .then((response) => {
        this.isLoading = false
        this.registers = response.data.data
        if (this.$route.query?.registerId) {
          this.registerId = parseInt(this.$route.query.registerId as string)
        }
        if (this.$route.query?.fromTime) {
          this.fromTime = this.$route.query.fromTime as string
        }
        if (this.$route.query?.toTime) {
          this.toTime = this.$route.query.toTime as string
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching ingoing balance')
        this.isLoading = false
      })
  }

  private get hasRegisterId(): boolean {
    return !!parseInt(this.registerId)
  }

  private clickSubmit() {
    this.$router.push({
      name: 'Payment/CashRegister/CashTransactions/SaftExport',
      query: { registerId: this.registerId, fromTime: this.fromTime, toTime: this.toTime },
    })
    this.load()
  }

  private load() {
    this.isLoading = true
    this.xml = ''
    this.$axios
      .post('/v4/site/payment/cash-registers/' + this.registerId + '/saft-export', {
        fromTime: this.fromTime,
        toTime: this.toTime,
      })
      .then((response) => {
        this.isLoading = false
        if (this.mode === 'inline') {
          this.xml = response.data.data.xml
        } else {
          this.download(response.data.data.xml)
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching SAF-T XML')
        this.isLoading = false
      })
  }

  private download(xml: string): void {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/xml,' + encodeURIComponent(xml)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'saft-export.xml'
    hiddenElement.click()
  }
}
